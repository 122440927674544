<template>
  <section class="m-merchant-goods m-scroll" ref="merchantGoods"  @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <div class="header">
      <img @click="handleBack" src="@/assets/new-product/icon-fanhui@2x.png" alt="back">
      <div class="search-box">
        <img src="@/assets/public/icon_search@2x.png" alt="search">
        <input v-model="search" type="text" placeholder="请输入关键词" @change="goSearch()">
      </div>
      <router-link to="/" >
        <img src="@/assets/merchant/icon_home_3@2x.png" alt="img">
      </router-link>
    </div>
    <div class="header-bg" :style="`height:${this.offsetTop <= 56 ? 38.5 : 23.46667  }vw;`"></div>
    <div class="merchant-info">
      <img :src="$store.state.merchant.pic_url" alt="merchant">
      <div class="center">
        <p>{{$store.state.merchant.title}}</p>
        <div>
          <div>
            <van-rate  v-model="$store.state.merchant.score" :size="12" color="#F03C18" void-icon="star" void-color="#ccc" readonly/>
          </div>
          <p>{{$store.state.merchant.collect_num}}人关注</p>
        </div>
      </div>
      <div class="followed" @click="handleFollowed()" v-if="$store.state.merchant.is_follow != null">
        <img src="@/assets/merchant/icon_guanzhu.png" alt="">
        <span>已关注</span>
      </div>
      <div class="followed" @click="handleFollowed()" v-if="$store.state.merchant.is_follow == null">
        <img src="@/assets/merchant/icon_guanzhu.png" alt="">
        <span>关注</span>
      </div>
    </div>
    <van-sticky offset-top="11.73vw">
        <div class="goods-filter">
          <div class="filter-item" :class="{'filter-active':tab == 1}" @click="handleTab(1)">推荐</div>
          <div class="filter-item" :class="{'filter-active':tab == 2}" @click="handleTab(2)">销量</div>
          <div class="filter-item" :class="{'filter-active':tab == 3}" @click="handleTab(3)">新品</div>
          <div class="filter-item" @click="handleTab(0)">
            <span :class="{'filter-active':tab == 4 || tab == 5}">价格</span>
            <img v-show="tab != 4 && tab != 5" src="@/assets/merchant/price (2).png" alt="">
            <img v-show="tab == 5" src="@/assets/merchant/price (3).png" alt="">
            <img v-show="tab == 4" src="@/assets/merchant/price (1).png" alt="">
          </div>
        </div>
    </van-sticky>
    <!-- 列表数据 -->
    <van-list  v-model="loading" :finished="finished" finished-text="赶紧去购买吧..." @load="onLoad" :immediate-check="false">
      <!-- 推荐排序 -->
      <goods-columns @cartClick="handleCart" :data="goodsList" :loading="goodsLoad" :columns="2"></goods-columns>
    </van-list>
    <foot-bar :id="$route.params.id"></foot-bar>
    <!-- <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading> -->
    <!-- 选择SKU 加入购物车 -->
    <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goods-id="goodsId"></m-sku>
    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>
    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>
  </section>
</template>

<script>
import MSku         from '@/components/zh/m-sku.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import CartFlex     from '@/components/zh/cart-flex.vue'
import FootBar      from '@/components/zh/merchant-footer.vue'
import { goods, follow } from '@/api/zh/merchant.js'

export default {
  name:'MerchantGoods',
  components:{ GoodsColumns, MSku, MAnimate, CartFlex, FootBar },
  data(){
    return {
      offsetTop:0,
      tab:1,
      loading:false,
      finished:false,
      skuModal:false,
      start:false,
      start_dom:null,
      goodsList: [],
      page: 1,
      goodsLoad: false,
      goodsId: '',
      search: ''
    }
  },
  methods:{
    // 内容滑动事件
    handleScroll(e){
      this.offsetTop = e.target.scrollTop
    },
    handleBack(){
      this.$router.go(-1)
    },
    // 上拉加载
    onLoad(){
      this.page++ 
      this.getGoods()
      console.log(1)
    },
    // tab 改变
    handleTab(index) {
      this.finished = false
      this.page = 1
      this.goodsList = []
      this.$refs.merchantGoods.scrollTop = 58
      if(index == 0) {
        console.log([4,5].includes(this.tab))
        if([4,5].includes(this.tab)) {
          if(this.tab == 4) {
            this.tab = 5
          } else {
            this.tab = 4
          }
        } else {
          this.tab = 4
        }
      }else{
        this.tab = index 
      }
      this.getGoods()
    },
    // 获取商品
    getGoods() {
      let params = { shops_id: this.$route.params.id, status: this.tab,  page: this.page }
      this.goodsLoad = true
      goods(params).then(res => {
        if(res) {
          this.goodsList = [...this.goodsList, ...res.data.data]
          if(this.page >= res.data.last_page) {
            this.finished = true
          }
        }else{
          this.goodsList = []
        }
      }).finally( () => {
        this.loading = false
      })
    },
    // 搜索
    goSearch() {
      if(this.search != '') {
        localStorage.setItem('back_path', this.$route.fullPath)
        this.$router.push( { path: '/zh/search', query: {s: 'merchant', key: this.search, id: this.$route.params.id} })
      }
    },
    // 点击关注
    handleFollowed() {
      let params = { merchant_id: this.$route.params.id }
      follow(params).then(res => {
        if(res) {
          if(res.msg.length == 4) {
            this.$store.state.merchant.collect_num ++
            this.$store.state.merchant.is_follow = 2
          }else {
            this.$store.state.merchant.collect_num --
            this.$store.state.merchant.is_follow = 1
          }
          this.$notify({ type: 'success', message: res.msg })
        }
      })
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
    // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        this.dotAnimate = true 
        this.cartNum ++
        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)
      },300)
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    }
  },
   created() {
    this.getGoods()
  }
}
</script>

<style lang="less" scoped>
@import './goods.less';
</style>